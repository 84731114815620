<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show="!edition">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'demandes'"
          :AllResponse="'alldemandes'"
          :headers="headers"
          :field_list="['DATE_DA', 'NO_DA']"
          :filename="'demandes'"
          :sheetname="'demandes'"
          @open="NewDemandeForm"
          @rowselect="DemandeChange"
          :showedit="false"
          :TypeScope="dem_type"
          :VarScope="editer || valider || convertir || traiter ? null : 1"
          :key="klist"
          :Add="editer"
          :del="false"
          :search_elm="filtre"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <demandeprint
          :key="kprint"
          :demande="demande"
          :dem_type="dem_type"
          :editer="editer"
          :valider="valider"
          :annuler="annuler"
          :convertir="convertir"
          :traiter="traiter"
          :technicien="technicien"
          :units="units"
          :modes="modes"
          :modalites="modalites"
          :agents="agents"
          @cancel="demande = selitem"
          @change="DemandeChange"
          :edition="edition"
          @edition="edit_change"
        >
        </demandeprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import DEMANDES from "../graphql/Demande/DEMANDES.gql";
import DATA from "../graphql/Demande/DATA.gql";
import ALLDEMANDES from "../graphql/Demande/ALLDEMANDES.gql";
import DELETE_DEMANDE from "../graphql/Demande/DELETE_DEMANDE.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    demandeprint: () => import("../components/DemandePrint.vue"),
  },
  props: {
    dem_type: Number,
  },
  data: () => ({
    filtre: "",
    editer: false,
    annuler: false,
    valider: false,
    convertir: false,
    traiter: false,
    technicien: false,
    klist: 100,
    drawer: null,
    selitem: {},
    edition: false,
    units: [],
    modes: [],
    modalites: [],
    agents: [],
    headers: [
      {
        text: "N°",
        value: "no_da",
        enum: "NO_DA",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        sm: 8,
        md: 8,
      },
      {
        text: "Date",
        value: "date_da",
        enum: "DATE_DA",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Etablit Par",
        value: "contact",
        enum: "CONTACT",
        selected: false,
      },
      {
        text: "Responsable",
        value: "agent_name",
        enum: "AGENT_NAME",
        selected: true,
        hiden: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        enum: "DEPOT_NAME",
        selected: true,
        hiden: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        list: ["Brouillon", "Att. Trait", "Att. Offre", "Convertie", "Annulée"],
        align: "text-center",
        slot: "chip",
        color: "statut_color",
        expire: true,
        selected: true,
      },
    ],
    demande: {},
    kprint: 100,
    loading: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return DEMANDES;
    },
    Qselectall() {
      return ALLDEMANDES;
    },
    Qdelete() {
      return DELETE_DEMANDE;
    },
  },
  watch: {
    dem_type: {
      async handler() {
        this.demande = {};
        this.$store.dispatch("Changed", true);
        await this.get_data();
      },
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre.filter_by;
        this.selitem = this.$store.state.filtre.obj;
        this.klist++;
      },
    },
  },

  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
  },

  async mounted() {
    this.editer =
      (this.dem_type == 1 && this.$store.state.auth.includes("02070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("02086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("02114")) ||
      this.$store.state.isadmin;
    this.valider =
      (this.dem_type == 1 && this.$store.state.auth.includes("03070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("03086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("03114")) ||
      this.$store.state.isadmin;
    this.annuler =
      (this.dem_type == 1 && this.$store.state.auth.includes("04070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("04086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("04114")) ||
      this.$store.state.isadmin;
    this.convertir =
      (this.dem_type == 1 && this.$store.state.auth.includes("05070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("05086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("05114")) ||
      this.$store.state.isadmin;
    this.traiter =
      (this.dem_type == 1 && this.$store.state.auth.includes("06070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("06086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("06114")) ||
      this.$store.state.isadmin;
    this.technicien =
      (this.dem_type == 1 && this.$store.state.auth.includes("07070")) ||
      (this.dem_type == 2 && this.$store.state.auth.includes("07086")) ||
      (this.dem_type == 3 && this.$store.state.auth.includes("07114")) ||
      this.$store.state.isadmin;
    this.headers[1].hiden = this.dem_type == 3;
    this.headers[4].hiden = this.dem_type != 3;
    this.headers[5].hiden = this.dem_type != 3;
    this.headers[1].text = this.dem_type == 1 ? "Destination" : "Client";
    this.headers[1].value = this.dem_type == 1 ? "destination" : "tier_name";
    this.headers[1].enum = this.dem_type == 1 ? "DESTINATION" : "TIER_NAME";
    this.headers[6].list =
      this.dem_type == 2
        ? ["Brouillon", "Att. Trait", "Att. Offre", "Convertie", "Annulée"]
        : ["Brouillon", "Encours", "Cloturée"];
    await this.get_data();

    // if (this.$route.params.cmd_id) {
    //   this.demande.id = -1;
    //   this.demande.cmd_id = this.$route.params.cmd_id;
    //   this.edition = true;
    //   this.kprint++;
    // }
    if (this.$route.params.dmd) {
      this.selitem = this.$route.params.dmd;
      this.demande = this.selitem;
      this.kprint++;
      this.klist++;
    }
  },

  methods: {
    edit_change(value) {
      this.edition = value;
    },
    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },

    async get_data() {
      let r = await this.requette(DATA);
      if (r) {
        this.agents = r.agents;
        this.units = r.units.filter((elm) => elm.uom_type == "m");
        this.modes = r.modes;
        this.modalites = r.modalites;
      }
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "cache-first",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    DemandeChange(item) {
      this.kprint++;
      this.demande = item;
      this.selitem = item;
      this.filtre = null;
    },
    NewDemandeForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.kprint++;
      this.demande = item;
    },
  },
};
</script>
